import React from "react";
import { Link, graphql } from "gatsby";
import { gsap } from "gsap";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Newsletter from "../../components/newsletter.js";
import CTA from "../../components/cta.js";
import OfferDetails from "../../components/offerDetails.js";
import PCatsOut from "../../components/pcatsOut.js";

class OfferPostTemplate extends React.Component {
  componentDidMount() {
    gsap.to("#a1", { x: 500, duration: 0 });
    gsap.to("#a1", { x: 0, duration: 5 });
    gsap.to("#a2", { x: -500, duration: 0 });
    gsap.to("#a2", { x: 0, duration: 5, delay: 1 });
  }

  render() {
    const { data } = this.props;

    //const siteTitle = this.props.data.site.siteMetadata.title
    //console.log(post)
    let portfolios = data.allMdx.edges.filter(function(e) {
      return (
        e.node.frontmatter.templateKey === "portfolio-page" &&
        e.node.frontmatter.language === "Polish"
      );
    });

    return (
      <Layout
        location={this.props.location}
        title="Rexer - Inżynieria środowiska"
      >
        <SEO
          title="Generalne wykonawstwo"
          description="Wykonujemy projekty zarówno pojedynczych zakresów jak i całego pakietu - od instalacji wodnych, kanalizacyjnych i grzewczych, po wentylacje, klimatyzacje oraz instalacje technologiczne."
        />
        <div className="x-wrapper">
          <div
            className="columns"
            style={{
              minHeight: "500px",
              padding: "0px",
              margin: "0px",
              alignItems: "center",
              backgroundColor: "white"
            }}
          >
            <div
              id="lcol"
              className="column nopad"
              style={{
                justifyContent: "center",
                padding: "2% 0%",
                maxWidth: "25%"
              }}
            >
              <div className="nopadleft" style={{ paddingLeft: "3rem" }}>
                <h1
                  style={{
                    textTransform: "uppercase",
                    fontSize: "2em",
                    marginTop: "30px"
                  }}
                  className="title"
                >
                  <strong style={{ color: "#00ff00" }}>
                    GENERALNE WYKONAWSTWO
                  </strong>
                  <br />
                  <span style={{ fontSize: ".5em" }}>
                    {" "}
                    <b>REXER</b> TO PRZEDE WSZYSTKIM OFERTA WYKONAWSTWA
                    INSTLALACJI SANITARNYCH I <b>HVAC</b>
                  </span>
                </h1>
                <hr
                  style={{
                    maxWidth: "150px",
                    backgroundColor: "#333333",
                    height: "3px"
                  }}
                />
                <div
                  className="show-mob"
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <img
                    id="mobile-x"
                    src="/img/x.svg"
                    style={{
                      marginBottom: "20px",
                      maxWidth: "500px",
                      marginTop: "-75px"
                    }}
                    width="auto"
                    height="auto"
                    alt="Rexer engineering"
                  />
                </div>
                <p>
                  Naszą <b>przewagą</b> są własne ekipy monterskie oraz
                  współpracujące mniejsze podmioty – gotowe <b>tu i teraz</b>.
                  Cechuje nas pewność i profesjonalizm, gwarantujemy tym samym
                  wysokiej jakości usługi inżynieryjne dla inwestycji
                  budowlanych.
                </p>
                <a href="#main" className="button rexer-button">
                  Dowiedz się więcej
                </a>
              </div>
              <br />
              <div
                className="column mob-hide is-two-thirds"
                style={{ position: "relative" }}
              >
                <img
                  id="a2"
                  style={{
                    marginTop: "-150px",
                    marginBottom: "-30%",
                    marginLeft: "-20px",
                    maxWidth: "550px",
                    opacity: "1"
                  }}
                  src="/img/hand-bulb.png"
                  width="auto"
                  alt="animacja-kula"
                />
              </div>
            </div>

            <div
              id="midcol"
              className="column"
              style={{ width: "50%", marginTop: "-100px", textAlign: "center" }}
            >
              <img
                src="/img/x.svg"
                style={{ marginBottom: "0" }}
                width="auto"
                height="auto"
                alt="Rexer engineering"
              />
              <p
                id="paddmob"
                style={{ textAlign: "center", marginTop: "-20px" }}
              >
                <a
                  href="#main"
                  style={{ border: "0px solid white", marginTop: "0px" }}
                  className="button standard-button"
                >
                  ↓
                </a>
              </p>
            </div>

            <div
              id="rcol"
              className="column"
              style={{
                maxWidth: "25%",
                margin: "0px",
                marginTop: "-150px",
                padding: "0px",
                alignItems: "end",
                display: "block",
                justifyContent: "center",
                marginRight: "3em"
              }}
            >
              <div
                id="a1"
                className="column"
                style={{
                  minHeight: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundImage: "url(/img/hero-bg-home.png)",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "bottom"
                }}
              >
                <div
                  style={{
                    marginTop: "50px",
                    margin: "0px",
                    padding: "0px",
                    alignItems: "flex-end",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <img
                    src="/assets/wykonastwo-icon.svg"
                    width="100%"
                    height="auto"
                    alt="Generalne wykonawstwo"
                    style={{
                      minWidth: "100px",
                      maxWidth: "250px",
                      opacity: "1",
                      margin: "0px",
                      marginRight: "-50%"
                    }}
                  />
                </div>
              </div>
              <div
                className="deco-w"
                style={{
                  border: "5px solid #333333",
                  padding: "20px",
                  marginTop: "3em",
                  marginLeft: "1rem"
                }}
              >
                <p
                  className="deco"
                  style={{ textAlign: "right", backgroundColor: "white" }}
                >
                  <b>„Wybuduj”</b> lub <b>„Zaprojektuj i Wybuduj”</b> – bierzemy
                  odpowiedzialność za całość.
                </p>
              </div>
              <p
                id="marginmob"
                style={{ textAlign: "right", marginTop: "3em" }}
              >
                <a className="button rexer-button" href="#details">
                  Poznaj szczegółową ofertę
                </a>
              </p>
            </div>
          </div>
        </div>

        <div
          id="main"
          className="is-bold"
          style={{
            textAlign: "left",
            backgroundColor: "#f6f6f6",
            padding: "5%"
          }}
        >
          <h2
            className="subtitle is-size-5"
            style={{
              borderLeft: "5px solid #00ff00",
              marginTop: "50px",
              paddingLeft: "10px",
              marginLeft: "-10px",
              textTransform: "uppercase"
            }}
          >
            OFERUJEMY <b>KOMPLEKSOWĄ OBSŁUGĘ</b> W BRANŻY INŻYNIERII ŚRODOWISKA
          </h2>
          <br />
          <p className="is-size-5" style={{ fontWeight: "400" }}>
            Znając swoje kompetencje, podejmujemy się realizacji
            najtrudniejszych projektów, w tym wsparcia lub przejęcia zakresu po
            innej firmie w trakcie realizacji całej inwestycji.
            <br />
            <br />
            Każdy model z naszym zaangażowaniem eliminuje nieścisłości i braki
            pomiędzy projektem a realizacją, zapewniając tym samym komfort
            Klientowi – nam transparentne przekazanie kompletnego produktu.
            Produktu, którego rozwiązania optymalizują działanie każdego układu,
            gwarantując ekonomiczne i efektywne działanie na lata. Tym samym
            minimalizujemy niekorzystny wpływ na środowisko zewnętrzne, dając
            użytkownikowi więcej czasu na rozwijanie swojego biznesu.
            <br />
          </p>
        </div>

        <div
          id="scroll-wrapper"
          className="home-cats"
          style={{
            overflowX: "scroll",
            backgroundColor: "#00ff00",
            display: "flex",
            flexWrap: "no-wrap",
            position: "relative",
            zIndex: "2"
          }}
        >
          <PCatsOut posts={portfolios} />
        </div>

        <div id="render" style={{ padding: "2.5% 5%" }}>
          {/*
          <div className="columns" style={{maxWidth:'100%',margin:'5% 2%'}}>
          <div className="column is-half" style={{padding:'3em',paddingLeft:'5%',minHeight:'300px'}}>
              <h2 className="is-size-3">DORADZTWO TECHNICZNE</h2>
              <br />
              <p>
              Pomagamy ocenić przygotowany projekt budowlany, techniczny jak i wykonawczy, sugerując wprowadzenie ewentualnych usprawnień korzystnych dla Klienta.
              <br /><br />
              </p>
              <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                <Link to="/kontakt#form">
                  <button className="button rexer-button is-size-6">Wyceń usługę</button>
                </Link>
              </div>
          </div>
          <div className="column is-half" style={{display:'flex',alignItems:'center'}}>
          <img className="rexer-button blog-img" src="/img/test3.jpg" alt="sponsoring" width='100%' />
          </div>
          </div>
*/}

          {/*  <div className="columns" style={{maxWidth:'100%',margin:'1%'}}>
          <div className="column is-half" style={{display:'flex',alignItems:'center'}}>
          <img className="rexer-button blog-img" src="/img/test3.jpg" alt="sponsoring" width='100%' />
          </div>
          <div className="column is-half" style={{padding:'3em',paddingLeft:'5%',minHeight:'300px'}}>
          <h2 className="is-size-3">AUDYT PROJEKTÓW</h2>
          <br />
          <p>
          Jesteśmy w stanie przeprowadzić pełny audyt sprawności systemów wbudowanych na obiekcie. Po testach przedstawiamy kierunki poprawiające funkcjonalność układów.
          <br /><br />
          </p>
              <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
                <Link to="/kontakt#form">
                  <button className="button rexer-button is-size-6">Wyceń usługę</button>
                </Link>
              </div>
          </div>
        </div>*/}
          {/*<div className="columns" style={{maxWidth:'100%',margin:'5% 2%'}}>
        <div className="column is-half" style={{padding:'3em',paddingLeft:'5%',minHeight:'300px'}}>
            <h2 className="is-size-3">COMMISIONING</h2>
            <br />
            <p>
            Wspieramy wiedzą i doświadczeniem naszej kadry każdy etap prac, aby efekt końcowy był zgodny z oczekiwaniami i bezawaryjnie służył przez wiele lat.
            <br /><br />
            </p>
            <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center'}}>
              <Link to="/kontakt#form">
                <button className="button rexer-button is-size-6">Wyceń usługę</button>
              </Link>
            </div>
        </div>
        <div className="column is-half" style={{display:'flex',alignItems:'center'}}>
        <img className="rexer-button blog-img" src="/img/test3.jpg" alt="sponsoring" width='100%' />
        </div>
        </div>      */}
          <hr id="details" />
          <div
            id="oferta"
            className="section"
            style={{ display: "block", position: "relative" }}
          >
            <h2
              className="subtitle is-size-4"
              style={{
                borderLeft: "5px solid #00ff00",
                fontWeight: "600",
                textTransform: "uppercase",
                marginTop: "50px",
                paddingLeft: "10px"
              }}
            >
              Szczegółowy zakres naszej oferty
              <br />
            </h2>
            <br />
            <p>
              Chętnie dzielimy się naszą wiedzą i doświadczeniem, aby budować
              wzajemne relacje oraz optymalizować proces projektowania, a więc i
              samej budowy. Do każdego Klienta podchodzimy po partnersku oraz
              poza usługami projektowymi oferujemy również:
            </p>
          </div>
          <OfferDetails />
          <br />
          <br />
        </div>

        <Newsletter />
        <CTA />
      </Layout>
    );
  }
}

export default OfferPostTemplate;

export const generalnepageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD. MM. YYYY")
            title
            language
            description
            templateKey
            image
            category
          }
        }
      }
    }
  }
`;
